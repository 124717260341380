var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("TableViewComponent", {
        attrs: {
          title: "Table foire aux questions",
          addItemLabel: "ajouter un élément de faq",
          itemLabel: "élément de faq",
          itemsLabel: "éléments de faq",
          items: _vm.entities,
          columns: _vm.buildColumns(),
          deleteItemLabel: "Voulez-vous supprimer cet élément ?",
          pagination: false,
          loading: _vm.loading,
          vcols: { xl: "6", lg: "8", md: "10", sm: "11", xs: "12" },
          rolesForEdition: _vm.rolesForEdition,
          rolesForAddDelete: _vm.rolesForAddDelete,
          rolesForRead: _vm.rolesForRead,
        },
        on: {
          addItemEvent: function ($event) {
            return _vm.onItem()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "beforetable",
            fn: function () {
              return [
                _c("v-card-text", [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mx-4 my-0 pa-0",
                        attrs: {
                          items: _vm.availableActivities,
                          "item-text": "digitalName",
                          "return-object": "",
                          clearable: "",
                          placeholder: "Choisir une activité",
                          "no-data-text": "aucune activité",
                          disabled: _vm.loading,
                        },
                        on: {
                          "click:clear": _vm.clearActivity,
                          change: function ($event) {
                            return _vm.onSelectedChange()
                          },
                        },
                        model: {
                          value: _vm.selectedActivity,
                          callback: function ($$v) {
                            _vm.selectedActivity = $$v
                          },
                          expression: "selectedActivity",
                        },
                      }),
                      _c("v-autocomplete", {
                        staticClass: "mx-4 my-0 pa-0",
                        attrs: {
                          items: _vm.availableSubActivity,
                          "item-text": "label",
                          "return-object": "",
                          clearable: "",
                          "no-data-text": "aucune sous-activité",
                          placeholder: "Choisir une sous-activité",
                          disabled: _vm.loading,
                        },
                        on: {
                          "click:clear": _vm.clearSubActivity,
                          change: function ($event) {
                            return _vm.onSelectedChange()
                          },
                        },
                        model: {
                          value: _vm.selectedSubActivity,
                          callback: function ($$v) {
                            _vm.selectedSubActivity = $$v
                          },
                          expression: "selectedSubActivity",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }