<template>
  <v-container fluid>
    <TableViewComponent
      title="Table foire aux questions"
      @addItemEvent="onItem()"
      addItemLabel="ajouter un élément de faq"
      itemLabel="élément de faq"
      itemsLabel="éléments de faq"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet élément ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '11', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    >
      <template v-slot:beforetable>
        <v-card-text>
          <div class="d-flex">
            <!-- Les activités -->
            <v-autocomplete
              v-model="selectedActivity"
              :items="availableActivities"
              item-text="digitalName"
              return-object
              clearable
              placeholder="Choisir une activité"
              class="mx-4 my-0 pa-0"
              no-data-text="aucune activité"
              :disabled="loading"
              @click:clear="clearActivity"
              @change="onSelectedChange()"
            >
            </v-autocomplete>

            <!-- Les sous activités -->
            <v-autocomplete
              v-model="selectedSubActivity"
              :items="availableSubActivity"
              item-text="label"
              return-object
              clearable
              no-data-text="aucune sous-activité"
              placeholder="Choisir une sous-activité"
              class="mx-4 my-0 pa-0"
              :disabled="loading"
              @click:clear="clearSubActivity"
              @change="onSelectedChange()"
            >
            </v-autocomplete>
          </div>
        </v-card-text>
      </template>
    </TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { FaqActivityService } from "@/service/conf/faq_activity_service.js";
import { FaqSubActivityService } from "@/service/conf/faq_subactivity_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableFaqs",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service de la vue */
      serviceFaqActivity: null,
      serviceFaqSubActivity: null,
      serviceActivity: null,

      /**les éléments */
      entities: [],
      allEntities: [],

      /** Pour les filtres */
      activities: [],
      subActivities: [],

      selectedActivity: null,
      selectedSubActivity: null,
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.faqs.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];
        // Récupère toutes les faqs liées aux activités et sous-activités
        let entitiesActivity = await this.serviceFaqActivity.getAll();
        let entitiesSubActivity = await this.serviceFaqSubActivity.getAll();

        // Récupère l'ensemble des activités
        this.activities = await this.serviceActivity.getActivities();
        // Récupère toutes les activités avec ses sous-activités
        let activitiesWithSubActivities = await this.serviceActivity.getActivitiesWithSubactivities();

        this.subActivities = [];
        // Parcours des activités pour en extraire un tableau simple d'activités
        for (let activity of activitiesWithSubActivities) {
          // Parcours chaque sous-activité et y ajoute l'id de son activité
          for (let subActivity of activity.subActivities) {
            subActivity.activityId = activity.activityId;

            this.subActivities.push(subActivity);
          }
        }

        // Chargement des faqs avec activités
        let faqsActs = this.loadFaqActivity(entitiesActivity, this.activities);
        this.entities.push(...faqsActs);

        // Chargement des faqs avec sous-activités
        let faqsSubAct = this.loadFaqSubActivity(
          entitiesSubActivity,
          this.subActivities,
          this.activities
        );
        this.entities.push(...faqsSubAct);

        // Tri des faqs par nom d'activité
        this.entities.sort(function(a, b) {
          return a.activityName.localeCompare(b.activityName);
        });
        // Tri des faqs par nom d'activité puis nom de sous-activité
        this.entities.sort(function(a, b) {
          if (a.subactivityName && b.subactivityName) {
            if (a.activityName === b.activityName) {
              return a.subactivityName.localeCompare(b.subactivityName);
            }
          }
        });

        // Copie des données dans l'autre tableau
        this.allEntities = JSON.parse(JSON.stringify(this.entities));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Chargement des faqs liés aux activités */
    loadFaqActivity(faqActivity, activities) {
      let entries = [];

      // Les entités lié aux activités
      faqActivity.forEach((e) => {
        let entity = JSON.parse(JSON.stringify(e));

        // Ajout de sous-activité pour compatibilité aces les faqs qui sont par sous-activité
        entity.subactivityId = null;
        entity.subactivityName = "";
        // Récupération de l'activité associées à la faq
        let foundActivity = activities.find((a) => a.id == entity.activityId);
        entity.activityName = foundActivity.digitalName;

        //action de consultation
        entity.view = () => {
          this.$router.push(
            routes.faqs.view.root +
              "/" +
              entity.id +
              "?activity=" +
              entity.activityId +
              "&subactivity="
          );
        };

        //action de modification
        entity.edit = () => {
          this.$router.push(
            routes.faqs.edit.root +
              "/" +
              entity.id +
              "?activity=" +
              entity.activityId +
              "&subactivity="
          );
        };

        //action de suppression
        entity.delete = async () => {
          try {
            await this.serviceFaqActivity.delete(entity.id);
            this.load();
          } catch (error) {
            console.error(error);
            this.addErrorToSnackbar(
              "suppression du statut : " +
                (exceptions.toMessage(error) || "problème technique")
            );
          }
        };

        entries.push(entity);
      });

      return entries;
    },

    /** Chargement des faqs liés aux sous-activités */
    loadFaqSubActivity(faqSubActivity, subActivities, activities) {
      let entries = [];

      // Les entités lié aux activités
      faqSubActivity.forEach((e) => {
        let entity = JSON.parse(JSON.stringify(e));

        // Récupération de la sous-activité lié à l'entité
        let foundSubActivity = subActivities.find(
          (s) => s.id == entity.subActivityId
        );
        entity.subactivityName = foundSubActivity.label;

        // Récupération de l'activité lié à la sous-activité
        let foundActivity = activities.find(
          (a) => a.id == foundSubActivity.activityId
        );
        entity.activityName = foundActivity.digitalName;
        entity.activityId = foundActivity.id;

        //action de consultation
        entity.view = () => {
          this.$router.push(
            routes.faqs.view.root +
              "/" +
              entity.id +
              "?activity=" +
              entity.activityId +
              "&subactivity=" +
              entity.subActivityId
          );
        };

        //action de modification
        entity.edit = () => {
          this.$router.push(
            routes.faqs.edit.root +
              "/" +
              entity.id +
              "?activity=" +
              entity.activityId +
              "&subactivity=" +
              entity.subActivityId
          );
        };

        //action de suppression
        entity.delete = async () => {
          try {
            await this.serviceFaqSubActivity.delete(entity.id);
            this.load();
          } catch (error) {
            console.error(error);
            this.addErrorToSnackbar(
              "suppression du statut : " +
                (exceptions.toMessage(error) || "problème technique")
            );
          }
        };

        entries.push(entity);
      });

      return entries;
    },

    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      return [
        {
          text: "Activité",
          align: "start",
          sortable: true,
          value: "activityName",
          default: true,
        },
        {
          text: "Sous-activité",
          align: "start",
          sortable: true,
          value: "subactivityName",
          default: true,
        },
        {
          text: "Question",
          align: "start",
          sortable: true,
          value: "question",
          cellClass: "truncate",
          default: true,
        },
        {
          text: "Réponse",
          align: "start",
          sortable: true,
          value: "answer",
          cellClass: "truncate",
          default: true,
        },
        {
          text: "Poids",
          align: "start",
          sortable: true,
          value: "weight",

          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];
    },

    // Force la sélection de l'activité
    selectActivityOfSubActivity(activity) {
      this.selectedActivity = activity;
    },

    // Supprime l'activité sélectionnée
    clearActivity() {
      this.selectedActivity = null;
    },

    // Supprime la sous-activité sélectionnée
    clearSubActivity() {
      this.selectedSubActivity = null;
    },

    // Changement d'activité ou de sous-activité
    onSelectedChange() {
      // Si une sous-activité est sélectionné, on filtre les items dessus
      if (this.selectedSubActivity) {
        this.entities = this.allEntities.filter(
          (e) => e.subActivityId == this.selectedSubActivity.id
        );
      } else if (this.selectedActivity) {
        // Si une activité est sélectionnée, on filtre les items dessus
        this.entities = this.allEntities.filter(
          (e) => e.activityId == this.selectedActivity.id
        );
      } else {
        // Si rien n'est sélectionné, on affiche tous les items
        this.entities = this.allEntities;
      }
    },
  },
  computed: {
    /** Retourne les activités disponibles selon si une sous-activité est sélectionnée */
    availableActivities() {
      let available = [];

      // Aucune activité choisie et une sous-activité sélectionnée, on retourne l'activité
      if (!this.selectedActivity && this.selectedSubActivity) {
        let foundActivity = this.activities.find(
          (a) => a.id == this.selectedSubActivity.activityId
        );

        if (foundActivity) {
          this.selectActivityOfSubActivity(foundActivity);
          available.push(foundActivity);
        }
      } else {
        available = this.activities;
      }
      // Tri des activités par nom digital
      available.sort(function(a, b) {
        return a.digitalName.localeCompare(b.digitalName);
      });

      return available;
    },

    /** Retourne les sous-activités disponibles selon si une activité est sélectionnée */
    availableSubActivity() {
      let available = [];

      // Aucune sous-activité et une activité choisie
      if (this.selectedActivity && !this.selectedSubActivity) {
        available = this.subActivities.filter(
          (s) => s.activityId == this.selectedActivity.id
        );
      } else {
        available = this.subActivities;
      }
      // Tri des sous-activités par label
      available.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });

      return available;
    },

    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditFaqs];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [RolesApplicationEnum.EditFaqs];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadFaqs];
    },
  },
  mounted() {
    // Instanciation des services
    this.serviceFaqActivity = new FaqActivityService(
      this.$api.getFaqActivity()
    );
    this.serviceFaqSubActivity = new FaqSubActivityService(
      this.$api.getFaqSubActivity()
    );
    this.serviceActivity = new ActivityService(this.$api);

    this.load();
  },
};
</script>

<style>
.truncate {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
